<template>
  <b-card-code title="Date Wise invoice Search Table">
    <div class="custom-search">
      <!-- advance search input -->
      <validation-observer ref="simpleRulesSearch">
        <b-form>
          <b-row>
             <b-col md="4">
              <b-form-group>
                <label for="from_date">From Date</label>
                <validation-provider #default="{ errors }" name="From Date">
                  <flat-pickr
                    v-model="from_date"
                    class="form-control"
                    :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
                    placeholder="From Date"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <label for="to_date">To Date</label>
              <validation-provider #default="{ errors }" name="To Date">
                <flat-pickr
                  v-model="to_date"
                  class="form-control"
                  :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
                  required
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>     
             <b-col md="4">
              <b-form-group>
                <label for=""> </label>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="info"
                  @click.prevent="searchInvoiceSettlement"
                >
                  Search Invoice
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </div>
   
    
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
      theme="my-theme"
    >
      <!-- Column: Actions -->
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'isp'" class="text-nowrap">
          <span>{{ props.row.isp.name }}</span>
        </span>
        <span
          v-if="props.column.field == 'settlement_from'"
          class="text-nowrap"
        >
          <span>{{ props.row.settlement_from }}</span>
        </span>
        <span v-if="props.column.field == 'settlement_to'" class="text-nowrap">
          <span>{{ props.row.settlement_to }}</span>
        </span>
        <span
          v-if="props.column.field == 'no_of_transaction'"
          class="text-nowrap"
        >
          <span>{{ props.row.no_of_transaction }}</span>
        </span>
        <span
          v-if="props.column.field == 'receivable_amount'"
          class="text-nowrap"
        >
          <span>{{ props.row.receivable_amount }}</span>
        </span>

        <span v-if="props.column.field == 'bank_trans_id'" class="text-nowrap">
          <span>{{ props.row.bank_trans_id }}</span>
        </span>
        <span v-if="props.column.field == 'status'" class="text-nowrap">
          <span>{{ props.row.status }}</span>
        </span>
        <div class="text-nowrap" v-if="props.column.field == 'actions'">
          <feather-icon
            :id="`invoice-row-${props.row.id}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="mx-1"
            @click="
              $router.push({
                name: 'admin-settlement-details',
                params: { settlementId: props.row.id },
              })
            "
          /> 
           <div>
            <b-sidebar
              :id="`sidebar-right-${props.row.id}`"
              bg-variant="white"
              sidebar-class="sidebar-lg"
              right
              backdrop
              shadow
            > 
            </b-sidebar>
          </div>
        </div>
         
      </template>
          
      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Showing 1 to </span>
            <b-form-select
              v-model="pageLength"
              :options="['10', '50', '100']"
              class="mx-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card-code>
</template>

<script>

import { avatarText } from '@core/utils/filter'
import { onUnmounted } from '@vue/composition-api'
import useInvoicesList from '@/views/isp/invoice/invoice-list/useInvoiceList'
import $ from "jquery";
import invoiceStoreModule from '@/views/isp/invoice/invoiceStoreModule'
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTable,BDropdown,BDropdownItem,BCard, BAvatar, BPagination,BFormGroup,BFormInput,BFormSelect,BRow,BCol,BLink,BTooltip,BMedia,BBadge,BButton,VBToggle,BSidebar, BForm,BFormTextarea,
} from "bootstrap-vue";
import { ISP_LIST, SETTLEMENT_LIST } from "@core/services/api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VueGoodTable } from "vue-good-table";
// import store from '@/store/index'
import store from "@/store";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import useJwt from "@/auth/jwt/useJwt";
import vSelect from "vue-select";
import { required, email } from "@validations";
import { checkRes } from "@core/services/helper";
// Use toast
import Mixin from "@core/services/mixin";

export default {
  mixins: [Mixin],
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    BCardCode,
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BLink,
    BTooltip,
    BMedia,
    BBadge,
    BButton,
    BSidebar,
    BForm,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    flatPickr,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    const statusOptions = [
      { value: 2, label: "Paid" },
      { value: 1, label: "Unpaid" },
      { value: 0, label: "Draft" },
    ];
    const resolveClientAvatarVariant = (status) => {
      if (status === "Partial Payment") return "primary";
      if (status === "Paid") return "danger";
      if (status === "Downloaded") return "secondary";
      if (status === "Draft") return "warning";
      if (status === "Sent") return "info";
      if (status === "Past Due") return "success";
      return "primary";
    };
    return {
      statusOptions,
      toggle: false,
      isShow: false,
      pageLength: 10,
      dir: false,
      required,
      columns: [
        { field: "isp", label: "USER", sortable: true },
        { field: "receivable_amount", label: "AMMOUNT",formatter: (val) => `${val} BDT`, },
        { field: "settlement_to", label: "PAID", sortable: true },
        { field: "receivable_amount", label: "JAN" },
        { field: "receivable_amount", label: "FEB"},
        { field: "receivable_amount", label: "MAR" },
        { field: "receivable_amount", label: "APR" },
        { field: "receivable_amount", label: "MAY" },
        { field: "receivable_amount", label: "JUN"},
        { field: "receivable_amount", label: "JUL" },
        { field: "receivable_amount", label: "AUG" },
        { field: "receivable_amount", label: "SEP" },
        { field: "receivable_amount", label: "OCT"},
        { field: "receivable_amount", label: "NOV" },
        { field: "receivable_amount", label: "DEC" },
      ],
      rows: [],
      invoice_ids: [],
      isp_list: [],
      isp_id:JSON.parse(localStorage.getItem('userData')),
      searchTerm: "",
      from_date: "",
      to_date: "",
      bank_trans_id: null,
      remakr: null,
      status: "",
      updateStatus: "",
      UpdateBankTransTd: null,
      settlementId: "",
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  created() {
    this.listSettlementData();
  },
  methods: {
    listSettlementData() {
      this.$http
        .get(process.env.VUE_APP_BASEURL + SETTLEMENT_LIST, {
          params: {
            isp_id: this.isp_id.id,
          },
        })
        .then((res) => {
          this.rows = res.data.data.settlementList;
        });
    },
    closeSideBar() {
      this.settlementId = null;
    }, 
    searchInvoiceSettlement() {
      this.$refs.simpleRulesSearch.validate().then((success) => {
        if (success) {
          this.$http
            .get(process.env.VUE_APP_BASEURL + SETTLEMENT_LIST, {
              params: {
                status: this.status,
                isp_id: this.isp_id.id,
                bank_trans_id: this.bank_trans_id,
                from_date: this.from_date,
                to_date: this.to_date,
              },
            })
            .then((res) => {
              this.rows = res.data.data.settlementList;
            });
        }
      });
    },
    idpListData() {
      this.$http
        .get(process.env.VUE_APP_BASEURL + ISP_LIST)
        .then((res) => {
          this.isp_list = res.data.data;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            userData.value = undefined;
          }
        });
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          useJwt
            .updateSettlement({
              settlementId: this.settlementId ?? null,
              status: this.updateStatus ?? null,
              bank_trans_id: this.UpdateBankTransTd ?? null,
              remark: this.remakr ?? null,
            })
            .then((response) => {
              this.listSettlementData();
              if (checkRes(response.status) && response.data.data) {
                this.isShow = false;
                this.$router
                  .push({ name: "admin-settlement-list" })
                  .catch((success) => {
                    this.toastMessage("success", "Invoice Payment", response);
                  });
              } else {
                this.$router
                  .push({ name: "admin-settlement-list" })
                  .then(() => {
                    this.toastMessage("warning", "Invoice Payment", response);
                  })
                  .catch((error) => {
                    this.toastMessage("danger", "Invoice Payment", response);
                  });
              }
            });
        }
      });
    },
  },
  mounted() {
    this.idpListData();
  },
};
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
